import { GiphyFetch } from "@giphy/js-fetch-api";
import { IGif } from "@giphy/js-types";
import {
  Carousel,
  Gif,
  Grid,
  Video,
  VideoOverlay,
  SearchBar, // the search bar the user will type into
  SearchContext, // the context that wraps and connects our components
  SearchContextManager, // the context manager, includes the Context.Provider
  SuggestionBar,
} from "@giphy/react-components";
import React, { useState, useContext } from "react";
import { createRoot } from "react-dom/client";
import { useDispatch } from "react-redux";
import ResizeObserver from "react-resize-observer";
import {
  setModalClose,
  setModalContent,
} from "../../../redux/attachmentsSlice";
import { giphyKey } from "../../../constants";

const giphyFetch = new GiphyFetch("sXpGFDGZs0Dv1mmNFvYaGUvYwKX0PWIh");

// the search experience consists of the manager and its child components that use SearchContext
const SearchExperience = () => (
  <SearchContextManager apiKey={giphyKey}>
    <Components />
  </SearchContextManager>
);

const Components = () => {
  const dispatch = useDispatch();
  const { fetchGifs, searchKey } = useContext(SearchContext);
  return (
    <>
      <SearchBar />
      <SuggestionBar />
      <Grid
        key={searchKey}
        columns={3}
        width={400}
        fetchGifs={fetchGifs}
        onGifClick={(gif, e) => {
          e.preventDefault();
          dispatch(setModalContent(gif));
          dispatch(setModalClose());
        }}
      />
    </>
  );
};

export function GifWindow() {
  return (
    <div>
      <SearchExperience />
    </div>
  );
}
