import React from "react";
import Modal from "react-modal";
import EmojiPicker from "emoji-picker-react";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { GifWindow } from "../gifs/gif-grid";
import { RootState } from "../../../app/store";
import { setEmojiModalClose } from "../../../redux/attachmentsSlice";
import styles from "./emoji-modal.module.css";
export function EmojiModal(onEmojiClick) {
  const isDesktop = useMediaQuery({
    query: "(min-width: 700px)",
  });
  const isModalOpen = useSelector(
    (state: RootState) => state.attachment_modal.isEmojiOpen
  );

  const dispatch = useDispatch();

  let customStyles = {
    content: {
      top: "50%",
      left: "0%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(55%, -40%)",
      backgroundColor: "#1A1A1A",
      borderRadius: "10px",
      padding: "20px",
      fontFamily: "oxanium",
    },
  };
  if (!isDesktop) {
    customStyles = {
      content: {
        top: "3%",
        left: "3%",
        right: "3%",
        bottom: "3%",
        marginRight: "0",
        transform: "translate(0%, 0%)",
        backgroundColor: "#1A1A1A",
        borderRadius: "10px",
        padding: "5px",
        fontFamily: "oxanium",
      },
    };
  }
  return (
    <Modal
      isOpen={isModalOpen}
      onAfterOpen={() => {}}
      onRequestClose={() => dispatch(setEmojiModalClose())}
      style={customStyles}
      contentLabel="User data modal"
      ariaHideApp={false}
    >
      <div className={styles["gif-window"]}>
        <EmojiPicker onEmojiClick={onEmojiClick} />
      </div>
    </Modal>
  );
}
