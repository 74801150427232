import cx from "classnames";
import React, { ReactElement } from "react";
import styles from "./page-menu-component.module.css";
type TileProps = {
  icon: ReactElement;
  iconLabel: string;
  activeTileName: string;
  navigateToPage: Function;
  isImplemented?: boolean;
};
const Tile: React.FC<TileProps> = ({
  iconLabel,
  icon,
  activeTileName,
  navigateToPage,
  isImplemented = false,
}: TileProps) => {
  function getClassname() {
    return cx(
      styles["tile"],
      activeTileName === iconLabel ? styles["active-tile"] : "",
      isImplemented ? styles["implemented"] : ""
    );
  }
  function getLabelClassname() {
    return cx(styles["icon-label"], isImplemented ? styles["implemented"] : "");
  }
  return (
    <div className={getClassname()} onClick={() => navigateToPage(iconLabel)}>
      <div className={styles["icon"]}>{icon}</div>
      <label className={getLabelClassname()}>{iconLabel}</label>
    </div>
  );
};

export { Tile };
