import { getDoc, doc, Firestore } from "firebase/firestore";
import { owner } from "../constants";
import { hasProps } from "./universal_functions";
import { useQuery } from "react-query";
import { useDebounce } from "use-debounce";
import { createReadStream } from "fs";
import axios from "axios";

export const fetchOwners = async () => {
  const token = document.cookie.replace(
    /(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );
  let accountsToAdd: owner[] = [];
  // switch firebase to mongo db using a route GET on /holders
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/holders`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = response.data;
    for (let [id, unknownUser] of Object.entries(data)) {
      let user = unknownUser as any;
      let newOwner: owner = {
        ens: user?.ens ?? null,
        address: user?.address ?? "ERROR READING ADDRESS",
        eyeverseRank: user?.total_eyeverse ?? 0,
        last_visit: user?.last_visit ?? 0,
        username: user?.username ?? null,
        bio: user?.bio ?? "",
        twitterHandle: user?.twitterHandle ?? "",
        pfp: user?.pfp ?? false,
      };
      accountsToAdd.push(newOwner);
    }
  } catch (err) {
    console.log(err);
  }

  accountsToAdd = accountsToAdd.sort((a, b) => b.eyeverseRank - a.eyeverseRank);
  return accountsToAdd;
};

export const fetchFilteredOwners = async (textFilter: string) => {
  let accountsToAdd = [];
  const token = document.cookie.replace(
    /(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/holders?filter=${textFilter}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = response.data;
    for (let [id, unknownUser] of Object.entries(data)) {
      let user = unknownUser as any;
      let newOwner: owner = {
        ens: user?.ens ?? null,
        address: user?.address ?? "ERROR READING ADDRESS",
        eyeverseRank: user?.total_eyeverse ?? 0,
        last_visit: user?.last_visit ?? 0,
        username: user?.username ?? null,
        bio: user?.bio ?? "",
        twitterHandle: user?.twitterHandle ?? "",
        pfp: user?.pfp ?? false,
      };
      accountsToAdd.push(newOwner);
    }
  } catch (err) {
    console.log(err);
    return [];
  }

  return accountsToAdd;
};
