import React, { useEffect, useState } from "react";
import {
  IoAccessibilitySharp,
  IoBasketSharp,
  IoConstructSharp,
  IoGameController,
  IoHomeSharp,
  IoSettingsSharp,
} from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import styles from "./page-menu-component.module.css";
import { Tile } from "./tile-component";

const PageMenuComponent: React.FC = () => {
  let currentPage = window.location.pathname;
  let navigate = useNavigate();
  const [activeTileName, setActiveTileName] = useState("Home");
  const size = "34px";

  useEffect(() => {
    switch (currentPage) {
      case "/":
        setActiveTileName("Home");
        break;
      case "/community":
        setActiveTileName("Community");
        break;
    }
  }, [currentPage]);

  function navigateToPage(newPage: string) {
    // setActiveTileName(newPage);
    switch (newPage) {
      case "Home":
        navigate("/");
        break;
      case "Community":
        // setActiveTileName("Community");
        navigate("/community");
        break;
    }
  }
  return (
    <div className={styles["container-with-button"]}>
      <div className={styles["container-tiles"]}>
        <Tile
          icon={<IoHomeSharp size={size} />}
          iconLabel={"Home"}
          activeTileName={activeTileName}
          navigateToPage={navigateToPage}
          isImplemented={true}
        ></Tile>
        <Tile
          icon={<IoAccessibilitySharp size={size} />}
          iconLabel={"Community"}
          activeTileName={activeTileName}
          navigateToPage={navigateToPage}
          isImplemented={true}
        ></Tile>
        <Tile
          icon={<IoGameController size={size} />}
          iconLabel={"Games"}
          activeTileName={activeTileName}
          navigateToPage={navigateToPage}
        ></Tile>
        <a
          style={{
            color: "inherit",
            textDecoration: "none",
            cursor: "pointer",
          }}
          target="_blank"
          rel="noreferrer"
          href={`https://www.eye.watch/`}
        >
          <Tile
            icon={<IoBasketSharp size={size} />}
            iconLabel={"Market"}
            activeTileName={activeTileName}
            navigateToPage={navigateToPage}
            // isImplemented={true}
          ></Tile>
        </a>
        <Tile
          icon={<IoConstructSharp size={size} />}
          iconLabel={"Tools"}
          activeTileName={activeTileName}
          navigateToPage={navigateToPage}
        ></Tile>
        <Tile
          icon={<IoSettingsSharp size={size} />}
          iconLabel={"Settings"}
          activeTileName={activeTileName}
          navigateToPage={navigateToPage}
        ></Tile>
      </div>
    </div>
  );
};

export { PageMenuComponent };
