import React, { useEffect } from "react";
import styles from "./header.module.css";
import logoUrl from "../../assets/eye/Eye.gif";
import { ConnectWalletButton } from "../dashboard/login";
import { setAccount } from "../../redux/accountSlice";
import { uid } from "../../utils/image_placeholder";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import eyeLogo from "../../assets/images/eye_logo.png";
import moonrunners_logo from "../../assets/images/moonrunners_logo.webp";
import { ModalContent } from "../../constants";
import { IoMdRefreshCircle } from "react-icons/io";
import MediaQuery from "react-responsive";
import { RiMenuFill, RiMenuFoldFill } from "react-icons/ri";
import { handleSideMenu } from "../../redux/helperSlice";
import {
  setModalData,
  setModalContent,
  setModalOpen,
} from "../../redux/modalSlice";
import { useAccount } from "wagmi";
import { Configuration } from "../../configuration";
const Header = () => {
  let dispatch = useDispatch();
  const { address } = useAccount();
  const currentOwner = useSelector((state: RootState) => state.user.owner);
  // const sideMenuOpen = useSelector(
  //   (state: RootState) => state.helper.sideMenuOpen
  // );
  const eye = useSelector((state: RootState) => state.helper.eye) || 0;

  function renderEye() {
    let eyeLabel = `${eye}`;
    if (eye > 1000) {
      eyeLabel = `${(eye / 1000).toFixed(1)}K`;
    }
    if (eye > 1000000) {
      eyeLabel = `${(eye / 1000000).toFixed(1)}M`;
    }
    return eyeLabel;
  }

  return (
    <div className={styles["header"]}>
      <MediaQuery minWidth={701}>
        <img
          src={
            Configuration.project_name === "Eyeverse"
              ? logoUrl
              : moonrunners_logo
          }
          alt="eye"
        />
      </MediaQuery>

      <MediaQuery maxWidth={700}>
        <div onClick={() => dispatch(handleSideMenu())}>
          {<RiMenuFill size="40px" color="white" />}
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={700}>
        <img
          src={
            Configuration.project_name === "Eyeverse"
              ? logoUrl
              : moonrunners_logo
          }
          alt="eye"
        />
      </MediaQuery>

      <div className={styles["row"]}>
        <MediaQuery maxWidth={700}></MediaQuery>
        <MediaQuery minWidth={701}>
          <div
            className={styles["eye-container"]}
            style={{ display: address?.length > 5 ? "flex" : "none" }}
          >
            <label>{renderEye()}</label>
            <img alt="$EYE" src={eyeLogo}></img>
          </div>
          {/* <div
            style={{ display: address?.length > 5 ? "flex" : "none" }}
            className={styles["button"]}
            onClick={() => {
              dispatch(setAccount(uid()));
            }}
          >
            <IoMdRefreshCircle color="#00be60" size="40px" />
          </div> */}
          <div className={styles["box"]} />
          <button
            style={{ display: address?.length > 5 ? "block" : "none" }}
            className={styles["profile-button"]}
            onClick={() => {
              dispatch(setModalData({ owner: { address: address } }));
              dispatch(setModalContent(ModalContent.USER_SETTINGS));
              dispatch(setModalOpen());
            }}
          >
            {currentOwner?.username || "PROFILE"}
          </button>
        </MediaQuery>

        <div style={{ margin: "10px" }}>
          <ConnectWalletButton></ConnectWalletButton>
        </div>
      </div>
    </div>
  );
};
export default Header;
