import { initializeApp } from "firebase/app";
import { getFirestore, getDoc, doc } from "firebase/firestore";
import {
  EYEMAP_CONTRACT_ADDRESS,
  EYEVERSE_CONTRACT_ADDRESS,
  EYEPHUNKS_CONTRACT_ADDRESS,
  EYE_CONTRACT_ADDRESS,
  PARADOX_CONTRACT_ADDRESS,
  REINCARNATED_CONTRACT_ADDRESS,
  COLLECTIBLES_CONTRACT_ADDRESS,
} from "./constants";

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "buidlr.firebaseapp.com",
  projectId: "buidlr",
  storageBucket: "buidlr.appspot.com",
  messagingSenderId: "322905631520",
  appId: "1:322905631520:web:0e7a7e9b06577fec420545",
  measurementId: "G-TM6XWWKV7V",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

export var isConfigLoaded3 = false;
export class Configuration {
  static project_name = "Eyeverse";
  static twitterHandle = "eyeversed";
  static project_names_map = {
    [EYEMAP_CONTRACT_ADDRESS]: "Eyemap",
    [EYEVERSE_CONTRACT_ADDRESS]: "Eyeverse",
    [EYEPHUNKS_CONTRACT_ADDRESS]: "Eyephunks",
    [EYE_CONTRACT_ADDRESS]: "$EYE",
    [PARADOX_CONTRACT_ADDRESS]: "Paradox",
    [REINCARNATED_CONTRACT_ADDRESS]: "Eyeverse Reincarnated",
    [COLLECTIBLES_CONTRACT_ADDRESS]: "Collectibles",
  };
  static project_contracts = [
    EYEMAP_CONTRACT_ADDRESS,
    EYEVERSE_CONTRACT_ADDRESS,
    EYEPHUNKS_CONTRACT_ADDRESS,
    EYE_CONTRACT_ADDRESS,
    PARADOX_CONTRACT_ADDRESS,
    REINCARNATED_CONTRACT_ADDRESS,
    COLLECTIBLES_CONTRACT_ADDRESS,
  ];
  static reservoir_collection_set =
    "12d13bff67d286aba11e0015f47611bd62ed9493d12097982d746c8da8ea3fad";
  static staking_contract = "0x45408ce844d0bf5061e9b25c2924aade4df884b3";
  static isConfigLoaded = false;
  public isConfigLoaded2 = false;
  //write constructor
  constructor(callback: Function) {
    try {
      // TODO:: before production change it to window.location.href
      const url = "https://eyeverse.buidlr.io"; //window.location.href;
      const startIndex = url.indexOf("https://") + "https://".length;
      const endIndex = url.indexOf(".", startIndex);
      const segment = url.substring(startIndex, endIndex);
      if (segment === "eyeverse") {
        // console.log(
        //   "Configuration is already loaded for eyeverse, skipping fetch"
        // );
        callback(true);
        return;
      }
      getDoc(doc(db, "projects", segment)).then((querySnapshot) => {
        const data = querySnapshot.data();
        Configuration.project_contracts = data.project_contracts;
        Configuration.project_name = data.project_name;
        Configuration.reservoir_collection_set =
          data.project_reservoir_collections_id;
        Configuration.staking_contract = data.project_staking_contract;
        Configuration.twitterHandle = data.project_twitter_handle;
        Configuration.project_names_map = data.project_names_map;
        callback(true);
      });
    } catch (e) {
      callback(true);
      console.log("Error fetching config for project", e);
    }
  }
}
