import { paths } from "@reservoir0x/reservoir-sdk";
// api keys

// eyeverse smart contracts
export const EYEVERSE_CONTRACT_ADDRESS =
  "0x249b90956ea0f80c2cb902dccde246b66a21d401";
export const EYEMAP_CONTRACT_ADDRESS =
  "0xdddc0ae3a8b3700101d1d78b3b0275c72afa072b";
export const REINCARNATED_CONTRACT_ADDRESS =
  "0x45408ce844d0bf5061e9b25c2924aade4df884b3";
export const COLLECTIBLES_CONTRACT_ADDRESS =
  "0x81a0f5ed53325666702dbf357734b6c8fdd0effb";
export const PARADOX_CONTRACT_ADDRESS =
  "0xd62291b8fc546cbcab104c75ef49fdce73df3a25";
export const EYE_CONTRACT_ADDRESS =
  "0x92d529163c5e880b9De86f01De0cB8924D790357";
export const EYEPHUNKS_CONTRACT_ADDRESS =
  "0x8caae73a009aa4248439534d0038c476daa35fcd";
export const EYEVERSE_CONTRACTS_ARRAY = [
  EYEMAP_CONTRACT_ADDRESS,
  EYEVERSE_CONTRACT_ADDRESS,
  EYEPHUNKS_CONTRACT_ADDRESS,
  EYE_CONTRACT_ADDRESS,
  PARADOX_CONTRACT_ADDRESS,
  REINCARNATED_CONTRACT_ADDRESS,
  COLLECTIBLES_CONTRACT_ADDRESS,
];
export const STAKING_COLLECTION = "0x45408ce844d0bf5061e9b25c2924aade4df884b3";
export const OLD_STAKING_CONTRACT =
  "0x491bafbE0b3b3D69E4dC7273e7cc8b7B9623EEf0";
export const NEW_STAKING_CONTRACT =
  "0x09FD382Db19c4481AA9A624d0a0DD657Ae174Ea0";
export const STAKING_CONTRACTS_ARRAY = [
  OLD_STAKING_CONTRACT,
  NEW_STAKING_CONTRACT,
];

export const EYEVERSE_RESERVOIR_COLLECTION_SET =
  "12d13bff67d286aba11e0015f47611bd62ed9493d12097982d746c8da8ea3fad";
export const BIG_ACCOUNT_FOR_TESTING =
  "0xf293cbcb3cf8b57108c871e56fc85ce26d206554";
export const collectionNames: Map<string, string> = new Map();
// types for reservoir
export type collectionDataArray =
  paths["/users/{user}/collections/v2"]["get"]["responses"]["200"]["schema"]["collections"];

export type Transaction = {
  price: number;
  type: string;
  date: number;
  collection: string;
  nftId: string;
  nftImage: string;
  to: string;
};
export type owner = {
  ens: string;
  address: string;
  eyeverseRank: number;
  last_visit: number;
  username: string;
  bio: string;
  twitterHandle: string;
  pfp: boolean;
};
export type collectionData = collectionDataArray[0];

export enum ModalContent {
  "NFT_DETAILS",
  "NFT_COLLECTION",
  "USER_PROFILE",
  "USER_SETTINGS",
  "NONE",
}
export const giphyKey = "t2fG7hjZUXOeIAljw3SunJWfE0PP2mjX";
export const openseaCollectionNames = {
  [EYEMAP_CONTRACT_ADDRESS]: "eyemap",
  [EYEVERSE_CONTRACT_ADDRESS]: "eyeverse-world",
  [EYEPHUNKS_CONTRACT_ADDRESS]: "eyephunks",
  [PARADOX_CONTRACT_ADDRESS]: "paradox-the-rally-of-blood",
  [REINCARNATED_CONTRACT_ADDRESS]: "eyeverse-reincarnated",
  [COLLECTIBLES_CONTRACT_ADDRESS]: "eyeverse-collectibles",
};

// Firebase configuration
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "buidlr.firebaseapp.com",
  projectId: "buidlr",
  storageBucket: "buidlr.appspot.com",
  messagingSenderId: "322905631520",
  appId: "1:322905631520:web:0e7a7e9b06577fec420545",
  measurementId: "G-TM6XWWKV7V",
};
