import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { collectionData } from "../constants";

export interface HelperState {
  chosenCollection: collectionData;
  spentOnCollection: number;
  collectionValue: number;
  eye: number;
  sideMenuOpen: boolean;
  currentPage: string;
}

const initialState: HelperState = {
  chosenCollection: null,
  spentOnCollection: 0,
  collectionValue: 0,
  eye: 0,
  sideMenuOpen: false,
  currentPage: "Home",
};

export const helperSlice = createSlice({
  name: "helperVariables",
  initialState,
  reducers: {
    setShowCollection: (state, action: PayloadAction<collectionData>) => {
      state.chosenCollection = action.payload;
    },
    setHideCollections: (state) => {
      state.chosenCollection = null;
    },
    setSpentOnCollection: (state, action: PayloadAction<number>) => {
      state.spentOnCollection = action.payload;
    },
    setCollectionValue: (state, action: PayloadAction<number>) => {
      state.collectionValue = action.payload;
    },
    setEyeBalance: (state, action: PayloadAction<number>) => {
      state.eye = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<string>) => {
      state.currentPage = action.payload;
    },
    handleSideMenu: (state) => {
      state.sideMenuOpen = !state.sideMenuOpen;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setShowCollection,
  setHideCollections,
  setSpentOnCollection,
  setCollectionValue,
  setEyeBalance,
  handleSideMenu,
} = helperSlice.actions;

export default helperSlice.reducer;
