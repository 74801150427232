import { ModalContent } from "../../constants";
import {
  setModalContent,
  setModalData,
  setModalOpen,
} from "../../redux/modalSlice";
// import { store } from "../../app/store";

export class ModalHistory {
  /**
   * logic for History compoennt:
   * it starts empty. Current modal window is added to history.
   * Every transition is added to history.
   * Navigating through history doesn't remove data from history.
   * It only moves pointer (currentLocation) to previous or next item.
   * When user closes modal, history is cleared.
   */
  // private static dispatch = useDispatch();
  private static storage: ModalState[] = [];
  private static currentLocation = 0;
  static capacity: number;
  static leftButtonEnabled = true;
  static rightButtonEnabled = true;
  constructor(private _capacity: number = Infinity) {
    ModalHistory.capacity = this._capacity;
  }

  public static checkButtonsStatus(): void {
    ModalHistory.leftButtonEnabled = ModalHistory.currentLocation > 0;
    ModalHistory.rightButtonEnabled =
      ModalHistory.currentLocation < ModalHistory.size() - 1;
  }

  public static push(item: ModalState): void {
    if (ModalHistory.size() === ModalHistory.capacity) {
      throw Error("Stack has reached max capacity, you cannot add more items");
    }
    //after pushing a new item remove all elements after current location and add new item
    ModalHistory.storage = ModalHistory.storage.slice(
      0,
      ModalHistory.currentLocation + 1
    );
    ModalHistory.storage.push(item);
    ModalHistory.currentLocation = ModalHistory.size() - 1;
    ModalHistory.checkButtonsStatus();
  }

  public static openPrevious(dispatch): void {
    if (ModalHistory.size() > 1 && ModalHistory.currentLocation > 0) {
      ModalHistory.currentLocation--;
      dispatch(dispatch(setModalOpen()));
      if (ModalHistory.peek()?.content === undefined) {
      }
      dispatch(setModalContent(ModalHistory.peek().content));
      dispatch(setModalData(ModalHistory.peek().data));
    }
    ModalHistory.checkButtonsStatus();
  }

  public static openNext(dispatch): void {
    if (ModalHistory.currentLocation < ModalHistory.size() - 1) {
      ModalHistory.currentLocation++;
      if (ModalHistory.peek()?.content === undefined) {
      }
      dispatch(dispatch(setModalOpen()));
      dispatch(setModalContent(ModalHistory.peek().content));
      dispatch(setModalData(ModalHistory.peek().data));
    }
    ModalHistory.checkButtonsStatus();
  }

  public static clear(): void {
    ModalHistory.storage = [];
    ModalHistory.currentLocation = 0;
  }

  public static pop(): ModalState {
    return ModalHistory.storage.pop();
  }

  public static peek(): ModalState {
    return ModalHistory.storage[ModalHistory.currentLocation];
  }

  public static size(): number {
    return ModalHistory.storage.length;
  }
}

export interface ModalState {
  isOpen: boolean;
  content: ModalContent;
  data: any;
}
