import { paths } from "@reservoir0x/reservoir-sdk";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { collectionLookup } from "../../../../utils/image_placeholder";
import styles from "./price-chart.module.css";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { Configuration } from "../../../../configuration";

let data = [];

export const SalesPriceChart: React.FC = () => {
  let contracts = useSelector(
    (state: RootState) => state.nfts_collections.value
  );
  let spent = useSelector((state: RootState) => state.helper.spentOnCollection);
  let value = useSelector((state: RootState) => state.helper.collectionValue);

  const [dayPriceMap, setDayPriceMap] = useState(new Map<Number, any>());
  const [data_state, setData] = useState([]);
  useEffect(() => {
    if (contracts.length === 0) return;
    const options = {
      method: "GET",
      headers: {
        accept: "*/*",
        "x-api-key": process.env.REACT_APP_RESERVOIR_API_KEY,
      },
      ContentType: "application/json",
    };
    contracts.forEach((contract) => {
      fetch(
        `https://api.reservoir.tools/collections/daily-volumes/v1?id=${contract.collection.primaryContract}&limit=21`,
        options
      )
        .then((response) => response.json())
        .then((res: any) => {
          const response =
            res as paths["/collections/daily-volumes/v1"]["get"]["responses"]["200"]["schema"];

          if (dayPriceMap.size === 0)
            response.collections.forEach((event) => {
              //fill map with empty objects
              setDayPriceMap(dayPriceMap.set(event.timestamp, {}));
            });

          response.collections.forEach((event) => {
            setDayPriceMap(
              dayPriceMap.set(event.timestamp, {
                ...dayPriceMap.get(event.timestamp),
                [collectionLookup(event.id)]: event.floor_sell_value,
              })
            );
          });
          data = [];
          dayPriceMap.forEach((v, timestamp) => {
            data.push({ timestamp, ...v });
          });
          setData(data.reverse());
        })
        .catch((err) => console.error(err));
    });
  }, [contracts]);

  function getRandomColor(): string {
    // Generate three random numbers between 0 and 255
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);

    // Convert the numbers to a hex string and pad with zeros if necessary
    const hexRed = red.toString(16).padStart(2, "0");
    const hexGreen = green.toString(16).padStart(2, "0");
    const hexBlue = blue.toString(16).padStart(2, "0");

    // Concatenate the hex values for each color and return the result
    return `#${hexRed}${hexGreen}${hexBlue}`;
  }

  return (
    <div>
      <div className={styles["title-row"]}>
        <div className={styles["title-label"]}>
          <div style={{ color: "#00D56E", paddingRight: "7px" }}>Spent</div>
          <div style={{ color: "#FFFFFF" }}>{spent.toFixed(3)}</div>
        </div>
        <div className={styles["title-label"]}>
          <div style={{ color: "#00D56E", paddingRight: "7px" }}>
            Floor value
          </div>
          <div style={{ color: "#FFFFFF" }}>{value.toFixed(3)}</div>
        </div>
      </div>
      <div className={styles["twitter-widget"]}>
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName={Configuration.twitterHandle}
          theme="dark"
          // options={{ height: 400 }}
        />
      </div>
    </div>
    // <ResponsiveContainer width="100%" height="100%">
    //   <LineChart
    //     width={650}
    //     height={300}
    //     data={data_state}
    //     margin={{
    //       top: 5,
    //       right: 10,
    //       left: 10,
    //       bottom: 5,
    //     }}
    //   >
    //     <CartesianGrid strokeDasharray="3 3" />
    //     <XAxis dataKey="timestamp" />
    //     <YAxis />
    //     <Tooltip />
    //     <Legend />
    //     {contracts.map((collection) => (
    //       <Line
    //         type="monotone"
    //         dataKey={collection.collection.name}
    //         stroke={getRandomColor()}
    //         activeDot={{ r: 1 }}
    //         strokeWidth={3}
    //         key={uid()}
    //       />
    //     ))}
    //   </LineChart>
    // </ResponsiveContainer>
  );
};
