import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface ModalState {
  isOpen: boolean;
  isEmojiOpen;
  content: any;
  messageText: string;
}

const initialState: ModalState = {
  isOpen: false,
  isEmojiOpen: false,
  content: null,
  messageText: "",
};

export const modalSlice = createSlice({
  name: "attachmentModal",
  initialState,
  reducers: {
    setModalOpen: (state) => {
      state.isOpen = true;
    },
    setModalClose: (state) => {
      state.isOpen = false;
    },
    setModalContent: (state, action: PayloadAction<any>) => {
      state.content = action.payload;
    },
    setEmojiModalOpen: (state) => {
      state.isEmojiOpen = true;
    },
    setEmojiModalClose: (state) => {
      state.isEmojiOpen = false;
    },
    setMessageText: (state, action: PayloadAction<string>) => {
      state.messageText = action.payload;
      console.log(state.messageText);
    },
    pushToMessageText: (state, action: PayloadAction<string>) => {
      state.messageText = state.messageText + action.payload;
      console.log(state.messageText);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setModalClose,
  setModalContent,
  setModalOpen,
  setEmojiModalClose,
  setEmojiModalOpen,
  setMessageText,
  pushToMessageText,
} = modalSlice.actions;

export default modalSlice.reducer;
