import { Alchemy, Network, Nft } from "alchemy-sdk";
import { EYE_CONTRACT_ADDRESS, OLD_STAKING_CONTRACT } from "../constants";
import { ethers } from "ethers";
import { Configuration } from "../configuration";
import axios from "axios";

export const fetchNFTs = async (account: string) => {
  const settings = {
    apiKey: process.env.REACT_APP_ALCHEMY_API,
    network: Network.ETH_MAINNET,
  };

  const alchemy = new Alchemy(settings);
  let nfts: Nft[] = [];
  let pageKey;
  //first run
  //TODO:: change it to dynamic array of contracts
  const nftsForOwner = await alchemy.nft.getNftsForOwner(account, {
    contractAddresses: Configuration.project_contracts, //EYEVERSE_CONTRACTS_ARRAY,
  });
  pageKey = nftsForOwner?.pageKey;
  const response = await alchemy.nft.getNftMetadataBatch(
    nftsForOwner.ownedNfts.map((nft) => {
      return { contractAddress: nft.contract.address, tokenId: nft.tokenId };
    })
  );
  nfts = response;
  //run until no pagekey is returned
  while (pageKey != null || pageKey != undefined) {
    const nftsForOwner = await alchemy.nft.getNftsForOwner(account, {
      contractAddresses: Configuration.project_contracts, //EYEVERSE_CONTRACTS_ARRAY,
      pageKey: pageKey,
    });
    pageKey = nftsForOwner?.pageKey;
    const response = await alchemy.nft.getNftMetadataBatch(
      nftsForOwner.ownedNfts.map((nft) => {
        return {
          contractAddress: nft.contract.address,
          tokenId: nft.tokenId,
        };
      })
    );
    nfts = nfts.concat(response);
  }
  //those lines will add the nfts that are staked
  //   await getStakedTokens(account, NEW_STAKING_CONTRACT).then((stakedNfts) => {
  //     if (stakedNfts?.length > 0) {
  //       nfts = nfts.concat(stakedNfts);
  //     }
  //   });
  //   await getStakedTokens(account, OLD_STAKING_CONTRACT).then((stakedNfts) => {
  //     if (stakedNfts?.length > 0) {
  //       nfts = nfts.concat(stakedNfts);
  //     }
  //   });

  return nfts;
};

export const fetchNFTsForContract = async (
  account: string,
  contract: string
) => {
  const token = document.cookie.replace(
    /(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );

  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/nfts/nfts?address=${account}&contract=${contract}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return await response.data;
};

export async function getStakedTokensForAccount(account: string) {
  let nfts = [];

  const token = document.cookie.replace(
    /(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );

  await getStakedTokens(account, Configuration.staking_contract).then(
    (stakedNfts) => {
      if (stakedNfts?.length > 0) {
        nfts = nfts.concat(stakedNfts);
      }
    }
  );
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/nfts/stakedNfts?address=${account}&project=${Configuration.project_name}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return await response.data;
}

export function groupObjectsByCollectionName(nfts: Nft[]) {
  return nfts.reduce((acc, obj) => {
    const { tokenId, contract } = obj;
    if (!acc[contract.address]) {
      acc[contract.address] = [];
    }
    acc[contract.address].push({ tokenId });
    return acc;
  }, {});
}

const minABI = [
  // balanceOf
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
];

export async function getErc20Balance(
  owner_address: string,
  contract_address = EYE_CONTRACT_ADDRESS
) {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const DAI = new ethers.Contract(contract_address, minABI, provider);
  return await DAI.balanceOf(owner_address);
}

async function getENS(address: string) {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  let name: string = await provider.lookupAddress(address);
  return name;
}

function filterStakedTokens(transactions: any[]) {
  let nfts = [];
  let nftsmap = new Map<string, number>();
  //get all staking transfers
  transactions
    .filter((transaction) => {
      return (
        transaction.to.toLowerCase() ===
          Configuration.staking_contract.toLowerCase() ||
        transaction.to.toLowerCase() === OLD_STAKING_CONTRACT.toLowerCase()
      );
    })
    .forEach((transaction) => {
      if (!nftsmap.has(transaction.tokenID)) {
        nftsmap.set(transaction.tokenID, 1);
      } else {
        nftsmap.set(transaction.tokenID, nftsmap.get(transaction.tokenID) + 1);
      }
    });

  //get all unstaking transfers
  transactions
    .filter((transaction) => {
      return (
        transaction.from === Configuration.staking_contract ||
        transaction.from === OLD_STAKING_CONTRACT
      );
    })
    .forEach((transaction) => {
      if (!nftsmap.has(transaction.tokenID)) {
        console.log("token unstaked without staking", transaction.tokenID);
      } else {
        nftsmap.set(transaction.tokenID, nftsmap.get(transaction.tokenID) - 1);
      }
    });
  nftsmap.forEach((value, key) => {
    if (value > 0) {
      nfts.push({
        contractAddress: Configuration.staking_contract,
        tokenId: key,
      });
    }
  });

  return nfts;
}

export async function getStakedTokens(owner: string, contract: string) {
  let results = [];
  const options = {
    method: "GET",
    ContentType: "application/json",
  };
  const url = `https://api.etherscan.io/api
	?module=account
	&action=tokennfttx
	&address=${owner}
	&sort=asc
	&apikey=${process.env.REACT_APP_ETHERSCAN_API_KEY}`;

  let response = await (await fetch(url, options)).json();
  console.log(response);
  let stakedNfts = filterStakedTokens(response.result);
  const settings = {
    apiKey: process.env.REACT_APP_ALCHEMY_API,
    network: Network.ETH_MAINNET,
  };

  const alchemy = new Alchemy(settings);
  if (stakedNfts.length === 0) {
    return [];
  }
  let fullNfts = [];
  //divide stakedNfts into batches of 100 and run them in getNftMetadataBatch
  for (let i = 0; i < stakedNfts.length; i += 100) {
    let batch = stakedNfts.slice(i, i + 100);
    let response = await alchemy.nft.getNftMetadataBatch(batch);
    fullNfts = fullNfts.concat(response);
  }
  let mappedNfts = fullNfts.map((nft) => {
    return {
      ...nft,
      isStaked: true,
    };
  });
  console.log(mappedNfts);
  return mappedNfts;
}

export async function getNumberOfStakedTokens(owner: string) {
  const options = {
    method: "GET",
    ContentType: "application/json",
  };
  const url = `https://api.etherscan.io/api
	?module=account
	&action=tokennfttx
	&address=${owner}
	&startblock=16420652
	&sort=asc
	&apikey=${process.env.REACT_APP_ETHERSCAN_API_KEY}`;

  let response = await (await fetch(url, options)).json();
  let stakedNfts = filterStakedTokens(response.result);
  return stakedNfts.length;
}

export function add3Dots(account_address: string, limit: number) {
  var dots = "...";
  if (account_address.length > limit) {
    account_address =
      account_address.substring(0, limit - 6) +
      dots +
      account_address.substring(limit - 2, limit);
  }
  return account_address;
}

export function hasProps<T extends Object, U extends string | number | symbol>(
  obj: T,
  ...propName: U[]
): obj is T & { [P in U]: unknown } {
  return obj && propName.every((x) => x in obj);
}
