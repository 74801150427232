import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { collectionData } from "../constants";

export interface NFTListState {
  value: collectionData[];
  images: {};
}

const initialState: NFTListState = {
  value: [],
  images: {},
};

export const nftCollectionsSlice = createSlice({
  name: "nftCollections",
  initialState,
  reducers: {
    clearCollections: (state) => {
      state.value = [];
    },
    addCollections: (state, action: PayloadAction<collectionData[]>) => {
      state.value.push(...action.payload);
    },
    addCollection: (state, action: PayloadAction<collectionData>) => {
      state.value = [
        ...state.value.filter(
          (collection) =>
            collection.collection.primaryContract !==
            action.payload.collection.primaryContract
        ),
        action.payload,
      ].sort(
        (a, b) =>
          Number(b.ownership.tokenCount) - Number(a.ownership.tokenCount)
      );
    },
    updateCollection: (
      state,
      action: PayloadAction<{ address: string; newNfts: number }>
    ) => {
      state.value = [
        ...state.value.filter(
          (collection) =>
            collection.collection.primaryContract !== action.payload.address // other collections unchanged
        ),
        ...state.value
          .filter(
            (collection) =>
              collection.collection.primaryContract === action.payload.address // changed collection updated
          )
          .map((collection) => {
            collection.ownership.tokenCount = (
              action.payload.newNfts + Number(collection.ownership.tokenCount)
            ).toString();
            return collection;
          }),
      ].sort(
        (a, b) =>
          Number(b.ownership.tokenCount) - Number(a.ownership.tokenCount)
      );
    },
    addImage: (
      state,
      action: PayloadAction<{ contract: string; image: string }>
    ) => {
      state.images[action.payload.contract] = action.payload.image;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addCollections,
  addCollection,
  addImage,
  clearCollections,
  updateCollection,
} = nftCollectionsSlice.actions;

export default nftCollectionsSlice.reducer;
