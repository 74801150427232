import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import styles from "./community.module.css";
import { add3Dots, uid } from "../../../../utils/image_placeholder";
import {
  setModalOpen,
  setModalData,
  setModalContent,
} from "../../../../redux/modalSlice";
import { ModalHistory } from "../../../modal/modal-history";
import { ModalContent, owner } from "../../../../constants";
import { handleSideMenu } from "../../../../redux/helperSlice";
import { useDebounce } from "use-debounce";
import { useQuery } from "react-query";
import { addNewOwners } from "../../../../redux/nftOwnersSlice";
import axios from "axios";

type props = {};

const CommunityListComponent: React.FC<props> = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 2000);
  const token = document.cookie.replace(
    /(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );
  let { isLoading, isError, data, error } = useQuery(
    ["holders", debouncedSearchQuery],
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/holders?filter=${debouncedSearchQuery}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let data = await response.data;
      dispatch(addNewOwners(data));
      return data;
    }
  );

  let isSideMenuOpen = useSelector(
    (state: RootState) => state.helper.sideMenuOpen
  );
  let dispatch = useDispatch();

  function openModalForUser(user: owner) {
    dispatch(setModalData({ owner: user }));
    dispatch(setModalContent(ModalContent.USER_PROFILE));
    dispatch(setModalOpen());
    if (isSideMenuOpen) dispatch(handleSideMenu());
    ModalHistory.push({
      data: { owner: user },
      content: ModalContent.USER_PROFILE,
      isOpen: true,
    });
  }

  function handleSearchInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchQuery(event.target.value);
  }

  return (
    <div>
      <div className={styles["title-label"]}>
        <div style={{ color: "#00D56E" }}>Community</div>
      </div>
      <div className={styles["community-container"]}>
        <input
          className={styles["community-search"]}
          type="text"
          placeholder="Search holders..."
          value={searchQuery}
          onChange={handleSearchInputChange}
        />
        {data === undefined
          ? []
          : data
              .filter((owner) => {
                return (
                  owner?.username
                    ?.toLowerCase()
                    .includes(debouncedSearchQuery.toLowerCase()) ||
                  owner.address
                    .toLowerCase()
                    .includes(debouncedSearchQuery.toLowerCase()) ||
                  owner?.ens
                    ?.toLowerCase()
                    .includes(debouncedSearchQuery.toLowerCase())
                );
              })
              .map((owner) => {
                let color = "white";
                if (owner?.username || owner?.pfp === true) color = "#00be60";
                return (
                  <div
                    onClick={() => openModalForUser(owner)}
                    className={styles["community-row"]}
                    key={uid()}
                  >
                    {/* <ImagePlaceHolder /> */}
                    <label style={{ cursor: "pointer", color: color }}>
                      {add3Dots(
                        owner?.username ?? owner?.ens ?? owner.address,
                        30
                      )}
                    </label>
                  </div>
                );
              })}
      </div>
    </div>
  );
};
export { CommunityListComponent };
