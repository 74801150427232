import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CircleLoader from "react-spinners/CircleLoader";
import { RootState } from "../app/store";
import img_test from "../assets/images/img_test.png";
import LazyLoad from "react-lazy-load";

import {
  COLLECTIBLES_CONTRACT_ADDRESS,
  EYEMAP_CONTRACT_ADDRESS,
  EYEPHUNKS_CONTRACT_ADDRESS,
  EYEVERSE_CONTRACT_ADDRESS,
  EYE_CONTRACT_ADDRESS,
  PARADOX_CONTRACT_ADDRESS,
  REINCARNATED_CONTRACT_ADDRESS,
} from "../constants";
import { MetaMaskAvatar } from "react-metamask-avatar";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import styles from "./images.module.css";
import { Configuration } from "../configuration";
type props = {
  contractAddress: string;
};
interface urlProps {
  url: string;
  linkToExternalWebsite?: string;
  onClick: () => void;
}
const hour = 60 * 60;
const day = 24 * hour;
const week = 7 * day;
const month = 30 * day;
const year = 365 * day;

export const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const DividerLine = () => {
  return (
    <div
      style={{ width: "100%", height: "1px", backgroundColor: "#E5E5E5" }}
    ></div>
  );
};

export function getTimeDescription(timestamp: number): string {
  const currentTime = new Date().getTime() / 1000; // convert to seconds
  const timeDiff = currentTime - timestamp;
  if (timeDiff < hour) {
    // less than 1 hour
    const minutes = Math.floor(timeDiff / 60);
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else if (timeDiff < day) {
    // less than 1 day (24 hours)
    const hours = Math.floor(timeDiff / hour);
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (timeDiff < week) {
    // less than 1 week (7 days)
    const days = Math.floor(timeDiff / day);
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (timeDiff < month) {
    // less than 1 month (30 days)
    const weeks = Math.floor(timeDiff / week);
    return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
  } else if (timeDiff < year) {
    // less than 1 year (365 days)
    const months = Math.floor(timeDiff / month);
    return `${months} month${months > 1 ? "s" : ""} ago`;
  } else {
    // more than 1 month
    return "more than a year ago";
  }
}

export const ImageFromUrl: React.FC<urlProps> = ({
  url,
  linkToExternalWebsite = "",
  onClick,
}) => {
  const [img, setImg] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (url != null && url != undefined) {
      if (url.indexOf("ipfs") != -1) {
        url = "https://ipfs.io/" + url.slice(7);
        console.log("modified url with ipfs:", url);
      }
      setImg(url);
      // const res = fetch(url)
      //   .then((res) => res.blob())
      //   .then((imageBlob) => URL.createObjectURL(imageBlob))
      //   .then((imageObjectURL) => setImg(imageObjectURL));
    }
  }, []);

  if (url === null || url === undefined) {
    return <ImagePlaceHolder />;
  }
  if (linkToExternalWebsite.length > 0) {
    return (
      <>
        <div>
          {<CircleLoader size="30px" color="#36d7b7" loading={loading} />}
        </div>
        <div
          style={{ display: loading ? "none" : "block" }}
          onClick={() => onClick}
        >
          <a
            style={{
              color: "inherit",
              textDecoration: "none",
              cursor: "pointer",
            }}
            target="_blank"
            rel="noreferrer"
            href={linkToExternalWebsite}
          >
            <LazyLoad height={30} width={30} offset={50}>
              <img
                style={{
                  borderRadius: "50%",
                  height: "30px",
                  width: "30px",
                  paddingRight: "5px",
                  cursor: "pointer",
                }}
                src={url}
                onLoad={() => setLoading(false)}
                alt="icons"
              />
            </LazyLoad>
          </a>
        </div>
      </>
    );
  } else {
    return (
      <>
        {/* <div>
          {<CircleLoader size="30px" color="#36d7b7" loading={loading} />}
        </div> */}
        <div
        // style={{ display: loading ? "none" : "block" }}
        >
          <LazyLoad height={30} width={30} offset={50}>
            <img
              style={{
                borderRadius: "50%",
                height: "30px",
                width: "30px",
                paddingRight: "5px",
                cursor: "pointer",
              }}
              src={img}
              onLoad={() => setLoading(false)}
              alt="icons"
              onClick={onClick}
            />
          </LazyLoad>
        </div>
      </>
    );
  }
};
export async function _ImageFromUrl({ url }) {
  const [img, setImg] = useState(null);
  const res = await fetch(url);
  const imageBlob = await res.blob();
  const imageObjectURL = URL.createObjectURL(imageBlob);
  setImg(imageObjectURL);
  return (
    <img
      style={{
        borderRadius: "50%",
        height: "24px",
        width: "24px",
        paddingRight: "5px",
      }}
      src={img}
      alt="icons"
    />
  );
}

export const ImageFromCollection: React.FC<props> = ({ contractAddress }) => {
  const [loading, setLoading] = useState(true);
  const [img, setImg] = useState(null);
  const images = useSelector(
    (state: RootState) => state.nfts_collections.images
  );
  useEffect(() => {
    setImg(images[contractAddress]);
  });
  return (
    <>
      <div>
        {<CircleLoader size="30px" color="#36d7b7" loading={loading} />}
      </div>
      <div style={{ display: loading ? "none" : "block" }}>
        <img
          style={{
            borderRadius: "50%",
            height: "24px",
            width: "24px",
            paddingRight: "5px",
          }}
          src={img}
          onLoad={() => setLoading(false)}
          alt="icons"
        />
      </div>
    </>
  );
};
export const ImagePlaceHolder: React.FC = () => (
  <img
    alt="default image"
    style={{
      borderRadius: "50%",
      height: "24px",
      width: "24px",
      paddingRight: "5px",
    }}
    src={img_test}
  />
);
export function collectionLookup(address: string): string {
  let name = Configuration.project_names_map[address];
  if (name) {
    return name;
  }
  return add3Dots(address, 18);
}
export function add3Dots(account_address: string, limit: number) {
  var dots = "...";
  if (account_address.length > limit) {
    account_address =
      account_address.substring(0, limit - 6) +
      dots +
      account_address.substring(
        account_address.length - 2,
        account_address.length
      );
  }
  return account_address;
}
export const uid = function () {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

export const getOwnerProfilePicture = (owner, size = 80) => {
  let image = <MetaMaskAvatar address={owner.address} size={size} />;
  // Initialize Firebase

  if (!owner?.pfp) {
    image = <MetaMaskAvatar address={owner.address} size={50} />;
  } else {
  }
  // getBase64(file).then((base64) => {
  //   localStorage["fileBase64"] = base64;
  //   console.debug("file stored", base64);
  // });
  return image;
};

/**
 *
 */
export const getOwnerProfilePictureFirebase = async (owner, size = 80) => {
  // Firebase configuration
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: "buidlr.firebaseapp.com",
    projectId: "buidlr",
    storageBucket: "buidlr.appspot.com",
    messagingSenderId: "322905631520",
    appId: "1:322905631520:web:0e7a7e9b06577fec420545",
    measurementId: "G-TM6XWWKV7V",
  };
  let image = <MetaMaskAvatar address={owner.address} size={size} />;
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  // Initialize Cloud Firestore and get a reference to the service
  const db = getFirestore(app);
  const storage = getStorage();

  if (!owner?.pfp) {
    image = <MetaMaskAvatar address={owner.address} size={50} />;
  } else {
    const pfpRef = ref(storage, `pfp/${owner.address}`);
    await getDownloadURL(pfpRef).then((url) => {
      image = (
        <img
          style={{
            height: size,
            width: size,
            objectFit: "cover",
            borderRadius: "50%",
            backgroundColor: "#00d56e",
            boxShadow: "0px 0px 10px 2px #00d56e",
          }}
          alt="pfp"
          src={url}
        ></img>
      );
    });
  }
  return image;
};
