import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./nft-showroom.module.css";
import modalStyles from "../../modal/modal-styles.module.css";
import { NftCollectionTile } from "./nft-collection-tile";
import { uid } from "../../../utils/image_placeholder";
import { BsArrowRightCircleFill, BsArrowLeftCircleFill } from "react-icons/bs";
import { REINCARNATED_CONTRACT_ADDRESS, owner } from "../../../constants";
import CircleLoader from "react-spinners/CircleLoader";
import { ModalHistory } from "../../modal/modal-history";
import { RiCloseCircleFill } from "react-icons/ri";
import { setModalClose } from "../../../redux/modalSlice";
import { Configuration } from "../../../configuration";
import { useQuery } from "react-query";
import axios from "axios";

type props = {
  owner: owner;
  setValue: (value: number) => void;
};
const NftShowroomComponent: React.FC<props> = (props) => {
  let dispatch = useDispatch();
  let totalValue = 0;
  const { owner, setValue } = props;

  const [nftContracts, setNftContracts] = useState([]);
  const [chosenCollection, setChosenCollection] = useState(null);
  let nftsArray = [];
  const collectionMenu = () => (
    <div className={styles["nft-collection-container"]}>
      {nftContracts?.map((contract) => (
        <NftCollectionTile
          contract={contract}
          setCollection={setChosenCollection}
          key={uid()}
          owner={owner}
        />
      ))}
    </div>
  );

  const token = document.cookie.replace(
    /(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );

  const { isLoading, error, data } = useQuery(
    ["nfts", owner.address, Configuration.project_name],
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/nfts/all?address=${owner.address}&collection=${Configuration.project_name}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        // console.log("Data loaded successfully:", data);
        setValue(data.value ?? 0);

        let allProjects: any[] = data.projectCollections;
        nftsArray = data.collections;
        allProjects.forEach((project, index) => {
          let userCollectionIndex = nftsArray.findIndex(
            (userCollection) =>
              userCollection.collection.id === project.collection.id
          );
          if (userCollectionIndex !== -1) {
            allProjects[index] = nftsArray[userCollectionIndex];
          }
        });

        let reincarnatedIndex = allProjects.findIndex((collection) => {
          return collection.collection.id === REINCARNATED_CONTRACT_ADDRESS;
        });
        let newCollectionObject = allProjects[reincarnatedIndex];

        newCollectionObject.ownership.tokenCount = (
          Number(newCollectionObject.ownership.tokenCount) +
          data.stakedNfts.length
        ).toString();

        allProjects[reincarnatedIndex] = newCollectionObject;
        setNftContracts([...allProjects]);
      },
    }
  );

  return (
    <div>
      <div className={styles["top-row"]}>
        <div className={modalStyles["modal-buttons-row"]}>
          <div
            className={
              ModalHistory.leftButtonEnabled
                ? modalStyles["left-nav-button"]
                : modalStyles["left-nav-button-disabled"]
            }
            id="left-button"
            onClick={() => ModalHistory.openPrevious(dispatch)}
          >
            <BsArrowLeftCircleFill size="25px" />
          </div>
          <div
            className={
              ModalHistory.rightButtonEnabled
                ? modalStyles["right-nav-button"]
                : modalStyles["right-nav-button-disabled"]
            }
            id="right-button"
            onClick={() => ModalHistory.openNext(dispatch)}
          >
            <BsArrowRightCircleFill size="25px" />
          </div>
          <div
            className={modalStyles["close-nav-button"]}
            id="right-button"
            onClick={() => {
              dispatch(setModalClose());
              // ModalHistory.openNext(dispatch)}
            }}
          >
            <RiCloseCircleFill size="30px" />
          </div>
        </div>
        <div className={styles["title-label"]}>
          <div style={{ color: "#FFFFFF" }}>Assets</div>
        </div>
      </div>
      <div>
        {<CircleLoader size="60px" color="#36d7b7" loading={isLoading} />}
      </div>
      {collectionMenu()}
    </div>
  );
};

export { NftShowroomComponent };
