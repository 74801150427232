import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { CommunityListComponent } from "../dashboard/dashboard-pages/community/community-list";
import { PageMenuComponent } from "../dashboard/dashboard-pages/page-menu/page-menu-component";
import {
  Avatar,
  ChatContainer,
  ConversationHeader,
  Message,
  MessageGroup,
  // MessageInput,
  MessageList,
} from "@chatscope/chat-ui-kit-react";
import MessageInput from "./copy/es/MessageInput/index";
import MediaQuery from "react-responsive";
import { useMediaQuery } from "react-responsive";
import { TwitterTimelineEmbed } from "react-twitter-embed";
// import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import "./chat-overrides.module.css";
import communityStyles from "./community-module.module.css";
import "./chat-overrides.css";
import { useAccount } from "wagmi";
import {
  ImageFromUrl,
  add3Dots,
  getOwnerProfilePicture,
  uid,
} from "../../utils/image_placeholder";
import { Configuration } from "../../configuration";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { OwnerAvatar } from "./avatar";
import { fetchFilteredOwners } from "../../utils/owner_functions";
import { addNewOwners } from "../../redux/nftOwnersSlice";
import { GifWindow } from "./gifs/gif-grid";
import { IGif } from "@giphy/js-types";
import { GiphyFetch } from "@giphy/js-fetch-api";
import { Gif } from "@giphy/react-components";
import { giphyKey } from "../../constants";
import { AttachModal } from "./attach-modal/attach-modal";
import {
  pushToMessageText,
  setEmojiModalOpen,
  setMessageText,
  setModalContent,
  setModalOpen,
} from "../../redux/attachmentsSlice";
import { RiCloseCircleFill } from "react-icons/ri";
import { EmojiModal } from "./emoji-modal/emoji-modal";
import { EmojiClickData } from "emoji-picker-react";
import axios from "axios";

type props = {};

const CommunityModule: React.FC<props> = () => {
  const gf = new GiphyFetch(giphyKey);
  const isDesktop = useMediaQuery({
    query: "(min-width: 700px)",
  });
  const isBelow500 = useMediaQuery({
    query: "(max-height: 500px)",
  });
  const chatRef = useRef();
  const dispatch = useDispatch();
  const { address } = useAccount();
  const [facts, setFacts] = useState([]);
  const [listening, setListening] = useState(false);
  const [hasProjectNfts, setHasProjectNfts] = useState(false);
  const [gif, setGif] = useState<IGif>(null);
  let chosenGif = useSelector(
    (state: RootState) => state.attachment_modal.content
  );

  let ownerNfts = useSelector((state: RootState) => state.nfts.value);
  let holders = useSelector((state: RootState) => state.nft_owners.value);
  let x = "";
  const [localMessageText, setLocalMessageText] = useState("");
  const messageText = useSelector(
    (state: RootState) => state.attachment_modal.messageText
  );
  let newMessages = [];
  const token = document.cookie.replace(
    /(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );

  async function getJWT() {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/holders/auth/${address}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.data;
    return data.token;
  }

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        debugger;
        if (error.response.status === 403 && !originalRequest._retry && token) {
          originalRequest._retry = true;
          console.log("refreshing token");
          try {
            const newToken = await getJWT();

            // const newToken = response.data.token;
            document.cookie = `token=${newToken}`;
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            console.log("refreshed token");
            return axios(originalRequest);
          } catch (error) {
            debugger;
            console.error("Failed to refresh token:", error);
            document.cookie = "";
            window.location.reload();
          }
        }

        return Promise.reject(error);
      }
    );
  }, [token]);

  function lookupUsername(address: string) {
    let username = address;
    if (!address) {
    }
    let holder = holders.find(
      (holder) => holder.address.toLowerCase() === address.toLowerCase()
    );
    if (holder === undefined) {
      fetchFilteredOwners(address).then((res) => {
        dispatch(addNewOwners(res));
        return res[0]?.username ?? res[0]?.ens ?? res[0]?.address;
      });
    } else {
      if (holder.address.toLowerCase() === address.toLowerCase()) {
        if (holder.username) {
          username = holder.username;
        } else if (holder.ens) {
          username = holder.ens;
        }
      }
      return username;
    }
  }

  useEffect(() => {
    if (!listening) {
      const events = new EventSource(
        `${process.env.REACT_APP_BACKEND_URL}/stream/group`
      );

      events.onmessage = (event) => {
        debugger;
        console.log("------new message arrived-------");
        const parsedData = JSON.parse(event.data);
        setFacts((facts) => facts.concat(parsedData));
        newMessages.push(parsedData.fullDocumeent);
        // queryClient.invalidateQueries({ queryKey: ['messages'] })
      };

      setListening(true);
    }
  }, [listening, facts]);

  useEffect(() => {
    // console.log(ownerNfts?.length >= 1);
    setHasProjectNfts(ownerNfts?.length >= 1);
  }, [ownerNfts]);

  function formatDate(date: Date) {
    const timeString = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    const dateString = date.toLocaleDateString([], {
      month: "2-digit",
      day: "2-digit",
    });
    return `${timeString} ${dateString}`;
  }

  function generateHeader(message) {
    if (isDesktop) {
      return `${lookupUsername(message.sender)} at ${formatDate(
        new Date(message.timestamp)
      )}`;
    } else {
      return `${lookupUsername(message.sender)} at ${formatDate(
        new Date(message.timestamp)
      )}`;
    }
  }

  function generateMessage(message) {
    if (message.gif) {
      return (
        <Message.Header>
          {/* {generateHeader(message)} */}
          <Gif backgroundColor="#999999" gif={message.gif} width={300} />
        </Message.Header>
      );
    } else if (message.message) {
      return (
        <Message
          key={uid()}
          model={{
            message: message.message,
            sentTime: castDateToString(message.timestamp),
            sender: lookupUsername(message.sender),
            direction: message.sender === address ? "outgoing" : "incoming",
            position: "single",
          }}
        ></Message>
      );
    }
  }

  function groupMessagesBySender() {
    let allMessages = [];
    if (newMessages) allMessages = [...newMessages];
    data?.pages?.forEach((page) => {
      if (page?.messages) allMessages = [...allMessages, ...page.messages];
    });
    allMessages = allMessages.sort((a, b) => {
      return a.timestamp - b.timestamp;
    });
    const messageGroups: JSX.Element[] = [];
    let currentGroup: JSX.Element[] = [];
    if (!allMessages || !allMessages[0]) return [];
    for (let i = 0; i < allMessages?.length; i++) {
      const message = allMessages[i];
      if (
        !message.sender ||
        !(message.message || message.gif) ||
        !message.timestamp
      )
        continue;
      const sender = message.sender;

      if (i === 0 || sender !== allMessages[i - 1].sender) {
        // If this is the first message or the sender has changed, create a new group
        if (currentGroup.length > 0) {
          messageGroups.push(
            <MessageGroup
              key={uid()}
              direction={
                allMessages[i - 1].sender === address ? "outgoing" : "incoming"
              }
              sender={lookupUsername(allMessages[i - 1].sender)}
              sentTime={castDateToString(allMessages[i - 1].timestamp)}
            >
              {/* <Avatar
                size={isDesktop ? "md" : "sm"}
                src={getOwnerProfilePicture(allMessages[i - 1].sender).then()}
                name={sender}
              /> */}
              {/* {getOwnerProfilePicture(allMessages[i - 1].sender)} */}
              {/* {OwnerAvatar(getOwnerProfilePicture(allMessages[i - 1].sender))} */}
              <MessageGroup.Messages>
                <MessageGroup.Header>
                  {generateHeader(allMessages[i - 1])}
                </MessageGroup.Header>
                {currentGroup}
              </MessageGroup.Messages>
            </MessageGroup>
          );
        }
        currentGroup = [generateMessage(message)];
      } else {
        // Otherwise, add the message to the current group
        currentGroup.push(generateMessage(message));
      }
    }

    // Add the last group
    if (currentGroup.length > 0) {
      const sender = allMessages[allMessages?.length - 1].sender ?? "0x000000";
      messageGroups.push(
        <MessageGroup
          key={uid()}
          direction={sender === address ? "outgoing" : "incoming"}
          sender={sender}
          sentTime={
            castDateToString(allMessages[allMessages?.length - 1]?.timestamp) ??
            "21-06-2023"
          }
        >
          {/* <Avatar size={isDesktop ? "md" : "sm"} src={logoUrl} name={sender} /> */}
          <MessageGroup.Header>
            {generateHeader(allMessages[allMessages?.length - 1])}
          </MessageGroup.Header>
          <MessageGroup.Messages>{currentGroup}</MessageGroup.Messages>
        </MessageGroup>
      );
      // messageGroups.push(
      //   <Message.Header>
      //     {generateHeader(allMessages[allMessages?.length - 1])}
      //     <Gif gif={gif} width={300} />
      //   </Message.Header>
      //   // gif !== null ? <Gif gif={gif} width={300} /> : null
      // );
    }
    return messageGroups;
  }

  const castDateToString = (date: any) => {
    let castDate;
    try {
      castDate = new Date(date).toLocaleDateString();
      return castDate;
    } catch (error) {
      console.log(error);
      return new Date();
    }
  };
  function mapToMessage(message: {
    timestamp: Date;
    sender: string;
    message: string;
  }) {
    return generateMessage(message);
  }

  function onChange(e: string) {
    x = e;
    setLocalMessageText(e);
  }

  function pushEmoji(emojiData: EmojiClickData, event: MouseEvent) {
    dispatch(pushToMessageText(emojiData.emoji));
    x += emojiData.emoji;
    setLocalMessageText(messageText + emojiData.emoji);
  }

  const fetchMessages = async ({ pageParam = 1 }) => {
    const response = await axios.get(
      `${
        process.env.REACT_APP_BACKEND_URL
      }/chat/group/${Configuration.project_name.toLowerCase()}?page=${pageParam}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  };

  const {
    isLoading,
    isError,
    error,
    data,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery(["messages"], fetchMessages, {
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.page + 1;
    },
  });

  const queryClient = useQueryClient();

  // When this mutation succeeds, invalidate any queries with the `todos` or `reminders` query key
  const mutation = useMutation({
    mutationFn: () => {
      if (chosenGif === null && messageText.length === 0) return;
      if (chosenGif != null) {
        return axios.post(
          `${
            process.env.REACT_APP_BACKEND_URL
          }/chat/groupgif/${Configuration.project_name.toLowerCase()}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              sender: address,
              gif: chosenGif,
              message: messageText,
            }),
          }
        );
      } else {
        return axios.post(
          `${
            process.env.REACT_APP_BACKEND_URL
          }/chat/group/${Configuration.project_name.toLowerCase()}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              sender: address,
              message: messageText,
            }),
          }
        );
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["messages"] });
      dispatch(setModalContent(null));
      setLocalMessageText("");
      dispatch(setMessageText(""));
    },
  });

  function generateMessages() {
    let messages: any[] = [];
    if (data) {
      data.pages.forEach((page) => {
        messages = messages.concat(page.messages);
      });
    }
    return messages;
  }

  const [isGifModalOpen, setIsGifModalOpen] = useState(false);
  function chooseGif(IGif) {
    setGif(IGif);
    setIsGifModalOpen(false);
  }

  return (
    <div>
      <div className={communityStyles["container-columns"]}>
        <MediaQuery minWidth={700}>
          <div className={communityStyles["column-left"]}>
            <div className={communityStyles["row-left-top"]}>
              <PageMenuComponent></PageMenuComponent>
            </div>
            <div className={communityStyles["row-left-bottom"]}>
              <CommunityListComponent></CommunityListComponent>
            </div>
          </div>
        </MediaQuery>

        <div className={communityStyles["column-center"]}>
          <div>
            {hasProjectNfts ? (
              <>
                <ChatContainer
                  style={{
                    // background: "#313740",
                    height: isBelow500 ? "80vh" : "88vh",
                    // width: "60vw",
                  }}
                >
                  <ConversationHeader
                    style={{ background: "transparent", border: "none" }}
                  >
                    {/* <Avatar
                    style={{
                      width: "40px",
                      height: "40px",
                      order: "2",
                      marginRight: "1em",
                      boxSizing: "border-box",
                      objectFit: "contain",
                    }}
                    src={logoUrl}
                    name="Eyeverse"
                  /> */}
                    <ConversationHeader.Content>
                      <div className={communityStyles["title-label"]}>
                        <div style={{ color: "#00D56E", paddingRight: "10px" }}>
                          Community
                        </div>
                        <div style={{ color: "#FFFFFF" }}>Chat</div>
                      </div>
                    </ConversationHeader.Content>
                  </ConversationHeader>
                  <MessageList>
                    <div
                      style={{
                        backgroundColor: "#00BE60",
                        borderRadius: "10px",
                        width: "90px",
                        height: "25px",
                        alignContent: "center",
                        justifyContent: "center",
                        display: "flex",
                        margin: "auto",
                        paddingTop: "10px",
                        marginTop: "10px",
                      }}
                      onClick={() => fetchNextPage()}
                    >
                      {isFetching ? "Loading..." : "Load more"}
                    </div>
                    {groupMessagesBySender()}
                  </MessageList>
                  {/* <div ref={chatRef}></div> */}
                </ChatContainer>
                <MediaQuery minWidth={701}>
                  <MessageInput
                    attachButton={true}
                    onGifClick={() => dispatch(setModalOpen())}
                    onEmojiClick={() => dispatch(setEmojiModalOpen())}
                    onAttachClick={() => dispatch(setModalOpen())}
                    placeholder="Type message here"
                    sendDisabled={messageText.length === 0 && chosenGif == null}
                    style={{
                      background: "#454447",
                      color: "white",
                    }}
                    onChange={onChange}
                    onSend={() => {
                      mutation.mutate();
                    }}
                  />
                </MediaQuery>
                <MediaQuery maxWidth={700}>
                  <MessageInput
                    attachButton={true}
                    onGifClick={() => dispatch(setModalOpen())}
                    onEmojiClick={() => dispatch(setModalOpen())}
                    onAttachClick={() => dispatch(setModalOpen())}
                    placeholder="Type message here"
                    sendDisabled={messageText.length === 0 && chosenGif == null}
                    style={{
                      background: "#454447",
                      color: "white",
                      // marginBottom: "50px",
                    }}
                    onChange={onChange}
                    onSend={() => {
                      mutation.mutate();
                    }}
                  />
                </MediaQuery>
              </>
            ) : (
              <div className={communityStyles["white-text"]}>
                Only holders of {Configuration.project_name} has access to chat
              </div>
            )}
            {AttachModal()}
            {EmojiModal(pushEmoji)}
            <div
              style={{
                zIndex: 2,
                height: 50,
                width: 50,
                bottom: 70,
                position: "absolute",
              }}
              onClick={() => dispatch(setModalContent(null))}
            >
              {chosenGif ? (
                <Gif
                  onGifClick={() => dispatch(setModalContent(null))}
                  gif={chosenGif}
                  width={50}
                  height={50}
                />
              ) : null}
              {chosenGif ? (
                <div
                  style={{
                    zIndex: 2,
                    bottom: 30,
                    left: 35,
                    position: "absolute",
                  }}
                  onClick={() => dispatch(setModalContent(null))}
                >
                  <RiCloseCircleFill size="20px" color="green" />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <MediaQuery minWidth={700}>
          <div className={communityStyles["column-right"]}>
            <div className={communityStyles["twitter-widget"]}>
              {/* <TwitterTimelineEmbed
                sourceType="profile"
                screenName={Configuration.twitterHandle}
                theme="dark"
                // options={{ height: 400 }}
              /> */}
            </div>
          </div>
        </MediaQuery>
      </div>
    </div>
  );
};
export { CommunityModule };
