import { useDispatch, useSelector } from "react-redux";
import styles from "./mobile-side-menu.module.css";
import { RootState } from "../../app/store";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { handleSideMenu } from "../../redux/helperSlice";
import { getOwnerProfilePicture } from "../../utils/image_placeholder";
import { useAccount } from "wagmi";
import { useEffect, useState } from "react";
import { RiMenuFill } from "react-icons/ri";
import { CommunityListComponent } from "../dashboard/dashboard-pages/community/community-list";
import { PageMenuComponent } from "../dashboard/dashboard-pages/page-menu/page-menu-component";
import { ModalContent } from "../../constants";
import {
  setModalData,
  setModalContent,
  setModalOpen,
} from "../../redux/modalSlice";
export function SideMenu() {
  const dispatch = useDispatch();
  let open = useSelector((state: RootState) => state.helper.sideMenuOpen);

  const [image, setImage] = useState<any>(null);
  let owner = useSelector((state: RootState) => state.user.owner);
  const { address } = useAccount();

  useEffect(() => {
    if (owner == null) return;
    setImage(getOwnerProfilePicture(owner, 60));
  }, [owner]);

  return (
    <div style={{ display: "flex", height: "100%", border: "none" }}>
      <Sidebar
        rootStyles={{
          border: "none",
        }}
        onBackdropClick={() => dispatch(handleSideMenu())}
        toggled={open}
        breakPoint="all"
        backgroundColor="black"
        collapsedWidth="0px"
      >
        <Menu>
          <div className={styles["row"]}>
            {" "}
            <div onClick={() => dispatch(handleSideMenu())}>
              {<RiMenuFill size="40px" color="white" />}
            </div>
            <div className={styles["image"]}>{image}</div>
          </div>

          <button
            style={{
              display: address && address.length > 5 ? "block" : "none",
            }}
            className={styles["profile-button"]}
            onClick={() => {
              dispatch(setModalData({ owner: { address: address } }));
              dispatch(setModalContent(ModalContent.USER_SETTINGS));
              dispatch(setModalOpen());
              dispatch(handleSideMenu());
            }}
          >
            {owner?.username || "PROFILE"}
          </button>
          <div className={styles["box"]} />
          <PageMenuComponent />
          <div className={styles["box"]} />
          <CommunityListComponent />
          <MenuItem> Documentation</MenuItem>
          <MenuItem> Calendar</MenuItem>
          <MenuItem> E-commerce</MenuItem>
          <MenuItem> Examples</MenuItem>
        </Menu>
      </Sidebar>
    </div>
  );
}
