import { Web3Button, useWeb3ModalTheme } from "@web3modal/react";
import { Nft } from "alchemy-sdk";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAccount } from "wagmi";
import axios from "axios";

import { REINCARNATED_CONTRACT_ADDRESS } from "../../constants";
import {
  addCollections,
  clearCollections,
  updateCollection,
} from "../../redux/nftCollections";
import {
  setCollectionValue,
  setEyeBalance,
  setSpentOnCollection,
} from "../../redux/helperSlice";
import { addAll } from "../../redux/nftSlice";
import { setCurrentOwner } from "../../redux/accountSlice";
import {
  getErc20Balance,
  groupObjectsByCollectionName,
} from "../../utils/universal_functions";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import { useMediaQuery } from "react-responsive";
import { fetchFilteredOwners } from "../../utils/owner_functions";
import { Configuration } from "../../configuration";
import { useQuery } from "react-query";
import {
  calculateValue,
  getCollections,
  getNfts,
  getSpent,
  getStakedNfts,
} from "../../utils/nfts_functions";
export const TOKEN_KEY = "refine-auth";
const token = document.cookie.replace(
  /(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/,
  "$1"
);

function calculateEyeverseSum(nfts: Nft[]) {
  //write some function that takes different values, $EYE, nfts etc into account
  return nfts.length;
}

const addUser = async (address: string, nfts: Nft[]) => {
  //send to mongo with uri /holders/{address} as post
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/holders/${address}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          address: address,
          nfts: groupObjectsByCollectionName(nfts),
          total_eyeverse: calculateEyeverseSum(nfts),
        }),
      }
    );
    return await response.data;
  } catch (error) {
    console.log(error);
  }
};

const ConnectWalletButton: React.FC = () => {
  let dispatch = useDispatch();
  const { setTheme } = useWeb3ModalTheme();
  // Set modal theme
  setTheme({
    themeMode: "dark",
    themeVariables: {
      "--w3m-font-family": "Roboto, sans-serif",
      "--w3m-accent-color": "#00be60",
    },
  });
  const { address } = useAccount();

  function mergeUserAndProjectCollections(userCollections, projectCollections) {
    let mergedCollections: any[] = projectCollections;
    let nftsArray = userCollections;
    mergedCollections.forEach((project, index) => {
      let userCollectionIndex = nftsArray.findIndex(
        (userCollection) =>
          userCollection.collection.id === project.collection.id
      );
      if (userCollectionIndex !== -1) {
        mergedCollections[index] = nftsArray[userCollectionIndex];
      }
    });

    return mergedCollections;
  }
  /**
   * This function resets list of nfts in redux, then fetches data about collections
   * and owners of this project. Then it finds the current owner and adds it to redux
   */
  async function doAccountUnawareTasks() {
    dispatch(addAll([]));
    //set initial user data
    dispatch(
      setCurrentOwner({
        address: address,
        ens: null,
        eyeverseRank: 0,
        last_visit: 0,
        username: null,
        bio: null,
        twitterHandle: null,
        pfp: false,
      })
    );
    let currentOwner = await fetchFilteredOwners(address);
    let currentUserData = await currentOwner;
    dispatch(setCurrentOwner(currentUserData[0]));
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const newToken = await getJWT();
        document.cookie = `token=${newToken}`;
      } catch (error) {
        console.log(error);
      }
    }, 59 * 60 * 1000); // 59 minutes

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleUserSpecificApiActions() {
    getErc20Balance(address).then((balance) => {
      dispatch(setEyeBalance(balance / 10 ** 18));
    });

    try {
      const collections = getCollections(address, token);

      const nfts = getNfts(address, token);
      const stakedNfts = getStakedNfts(address, token);
      const spent = getSpent(address, token);
      const resolvedCollections = await collections;

      dispatch(
        addCollections(
          mergeUserAndProjectCollections(
            resolvedCollections.collections,
            resolvedCollections.projectCollections
          )
        )
      );
      const resolvedNfts = await nfts;
      const resolvedStakedNfts = await stakedNfts;
      dispatch(addAll([...resolvedNfts, ...resolvedStakedNfts]));

      updateCollectionsWithStakedNfts(resolvedStakedNfts);
      const resolvedSpent = await spent;
      dispatch(setSpentOnCollection(resolvedSpent ?? 0));
      const value = calculateValue(
        resolvedNfts,
        resolvedStakedNfts,
        resolvedCollections.projectCollections
      );
      console.log(value);
      dispatch(setCollectionValue(value ?? 0));
    } catch (error) {
      console.log(error);
    }
  }

  const isMobile = useMediaQuery({
    query: "(max-width: 700px)",
  });

  async function getJWT() {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/holders/auth/${address}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.data;
    return data.token;
  }

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        debugger;
        if (error.response.status === 403 && !originalRequest._retry && token) {
          originalRequest._retry = true;
          console.log("refreshing token");
          try {
            const newToken = await getJWT();

            // const newToken = response.data.token;
            document.cookie = `token=${newToken}`;
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            console.log("refreshed token");
            return axios(originalRequest);
          } catch (error) {
            console.error("Failed to refresh token:", error);
            document.cookie = "";
            window.location.reload();
          }
        }

        return Promise.reject(error);
      }
    );
  }, [token]);

  async function showMobileModal() {
    const provider = await EthereumProvider.init({
      projectId: process.env.REACT_APP_WALLETCONNECT_ID, // required
      chains: [1], // required
      showQrModal: true, // requires @walletconnect/modal
    });
    provider
      .enable()
      .then(() => {
        doAccountUnawareTasks();
        handleUserSpecificApiActions();
      })
      .catch((e) => {
        if (provider !== null) {
          doAccountUnawareTasks();
          handleUserSpecificApiActions();
        }
      });
  }

  function resetStateAfterAccountChange() {
    dispatch(addAll([]));
    dispatch(clearCollections());
    dispatch(setCollectionValue(0));
    dispatch(setSpentOnCollection(0));
  }

  useEffect(() => {
    resetStateAfterAccountChange();

    if (address?.length < 3) {
      return;
    }
    getJWT().then((token) => {
      document.cookie = `token=${token}`;

      if (isMobile && !address) {
        showMobileModal();
      } else {
        doAccountUnawareTasks();
        handleUserSpecificApiActions();
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  function updateCollectionsWithStakedNfts(stakedNfts: any[]) {
    dispatch(
      updateCollection({
        address: Configuration.staking_contract,
        newNfts: stakedNfts.length,
      })
    );
  }

  return <Web3Button />;
};

export { ConnectWalletButton };
