import { paths } from "@reservoir0x/reservoir-sdk";
import { EYEVERSE_RESERVOIR_COLLECTION_SET } from "../constants";
import { Configuration } from "../configuration";
import axios from "axios";

const options = {
  method: "GET",
  headers: {
    accept: "*/*",
    "x-api-key": process.env.REACT_APP_RESERVOIR_API_KEY,
  },
  ContentType: "application/json",
};

export async function getCollectionsForUser(address: string) {
  //fetch all eyeverse collections
  //add them to store
  //then fetch user collections and overwrite the ones that are already in the store
  let promise = await axios.get(
    `https://api.reservoir.tools/users/${address}/collections/v3/?collectionsSetId=${Configuration.reservoir_collection_set}`,
    options
  );
  let res = promise.data;
  // .then((response) => response.json())
  // .then((res: any) => {
  const response =
    res as paths["/users/{user}/collections/v2"]["get"]["responses"]["200"]["schema"];
  //   "--------------------USER COLLECTIONS-----------------",
  //   response
  // );
  // const filteredResponse = response.collections.filter((contract) =>
  //   EYEVERSE_CONTRACTS_ARRAY.includes(contract.collection.primaryContract)
  // );
  const filteredResponse = response.collections;
  // filteredResponse.forEach((collection) => {
  //   dispatch(addCollection(collection));
  // });
  // dispatch(addCollections(filteredResponse));
  let value = 0;
  filteredResponse.forEach((response) => {
    let newValue =
      Number(response.ownership.tokenCount) *
      response.collection.floorSale["7day"];
    if (newValue > 0) {
      value += newValue;
    }
  });
  //   user_value = value;
  //   dispatch(setCollectionValue(value));
  // calculateCollectionValue(nfts, filteredResponse);
  return { userCollections: filteredResponse, value };
  // })
  // .catch((err) => console.error(err));
}

export async function getEyeverseCollections() {
  let resultPromise = await axios.get(
    `https://api.reservoir.tools/collections/v5?collectionsSetId=${Configuration.reservoir_collection_set}`,
    options
  );
  let res = resultPromise.data;

  const response =
    res as paths["/collections/v5"]["get"]["responses"]["200"]["schema"];

  let allCollectionsData = response.collections;

  return allCollectionsData;
}

export async function getStakedNfts(user_address, token) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/nfts/stakedNfts?address=${user_address}&project=${Configuration.project_name}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = response.data;
    return data.stakedNfts;
  } catch (e) {
    console.log(e);
  }
}

export async function getNfts(user_address, token) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/nfts/nfts?address=${user_address}&project=${Configuration.project_name}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = response.data;
    return data.nfts;
  } catch (e) {
    console.log(e);
  }
}

export async function getSpent(user_address, token) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/nfts/spent?address=${user_address}&project=${Configuration.project_name}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = response.data;
    return data.spent;
  } catch (e) {
    console.log(e);
  }
}

export async function getCollections(user_address, token) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/nfts/collectionsData?address=${user_address}&project=${Configuration.project_name}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = response.data;
    return data;
  } catch (e) {
    console.log(e);
  }
}

export function calculateValue(nfts, stakedNfts, projectCollections) {
  let value = 0;
  const allNfts = [];
  const seenTokenIds = new Set();
  try {
    for (const item of nfts.concat(stakedNfts)) {
      if (!seenTokenIds.has(item.tokenId)) {
        seenTokenIds.add(item.tokenId);
        allNfts.push(item);
      }
    }
    const groupedNfts = allNfts.reduce((acc, nft) => {
      const contractAddress = nft.contract.address;
      if (!acc[contractAddress]) {
        acc[contractAddress] = [];
      }
      acc[contractAddress].push(nft);
      return acc;
    }, {});

    for (const key in groupedNfts) {
      let group = groupedNfts[key];
      console.log(
        projectCollections.find(
          (collection) => collection.collection.id === key
        )
      );
      let price =
        projectCollections.find(
          (collection) => collection.collection.id === key
        )?.collection?.floorSale["7day"] ?? 0;
      let newvalue = price * group.length;
      value += newvalue;
    }
    return value;
  } catch (e) {
    console.log(e);
    return 0;
  }
}
