import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../redux/accountSlice";
import counterReducer from "../redux/counterslice";
import nftReducer from "../redux/nftSlice";
import nftOwnersReducer from "../redux/nftOwnersSlice";
import helperRedcer from "../redux/helperSlice";
import nftsByCollectionReducer from "../redux/nftCollections";
import transactionsReducer from "../redux/transactionsSlice";
import modalReducer from "../redux/modalSlice";
import attachmentReducer from "../redux/attachmentsSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    helper: helperRedcer,
    counter: counterReducer,
    nfts: nftReducer,
    nft_owners: nftOwnersReducer,
    nfts_collections: nftsByCollectionReducer,
    transactions: transactionsReducer,
    modal: modalReducer,
    attachment_modal: attachmentReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
