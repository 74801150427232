import "./App.css";
import "./assets/fonts/Oxanium-VariableFont_wght.ttf";

import { Dashboard } from "./pages/dashboard/dashboard";
// Web3, wagmi imports
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { WagmiConfig, configureChains, createClient } from "wagmi";
import { arbitrum, mainnet, polygon } from "wagmi/chains";
import { CommunityModule } from "./pages/community/community-module";
import styles from "./pages/dashboard/dashboard.module.css";
import Header from "./pages/header/header";
import { MainModal } from "./pages/modal/modal";
import { SideMenu } from "./pages/mobile-side-menu/mobile-side-menu";
import { useEffect, useState } from "react";
import { Configuration } from "./configuration";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
const chains = [arbitrum, mainnet, polygon];
const projectId = process.env.REACT_APP_W3M_PROJECT_ID;

const { provider } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  provider,
});
const queryClient = new QueryClient();

function Layout() {
  return (
    <div className={styles["container-header"]}>
      <Header />
      <SideMenu />
      {/* This element will render all necessary elements as children, while header and 
      sidebar is always there and are connected to route provider
      */}
      <Outlet />
    </div>
  );
}

const ethereumClient = new EthereumClient(wagmiClient, chains);

function App() {
  const [isConfigLoaded, setIsConfigLoaded] = useState(true);
  useEffect(() => {
    new Configuration(setIsConfigLoaded);
    // console.log(
    //   "Using configuration for project ",
    //   Configuration?.project_name
    // );
  }, []);
  useEffect(() => {
    // console.log("Configuration.isConfigLoaded: ", Configuration.isConfigLoaded);
    setIsConfigLoaded(isConfigLoaded);
  }, [isConfigLoaded]);

  return (
    <div className="App">
      {isConfigLoaded ? (
        <div>
          <WagmiConfig client={wagmiClient}>
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<Layout />}>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/community" element={<CommunityModule />} />
                  </Route>
                </Routes>
              </BrowserRouter>
            </QueryClientProvider>
          </WagmiConfig>
          <QueryClientProvider client={queryClient}>
            <MainModal />
          </QueryClientProvider>
          <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
        </div>
      ) : (
        <label>loading config</label>
      )}
    </div>
  );
}

export default App;
